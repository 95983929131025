import { useNavigate } from "react-router";
import { Navbar, Nav, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ModalTemplate from '../components/Modal'

// import ShareBtn from "../components/ShareBtn";
// import logo from "../images/phoenixLogoSmall.png";

export const NavbarBar = () => {
    const training = <> <div className="iframeContainer"><iframe title="schedule" scrolling="yes" src="https://momence.com/TrainWithJill/appointment-reservation/11176?boardId=1494&venueId=2073" style={{ width: "100%", minHeight: '1000px' }}></iframe></div> </>;

    const videoLibrary = <> <div className="iframeContainer"><iframe title="videoLibrary" src="https://momence.com/video/plugin/11176" style={{ width: "100%", minHeight: '1000px' }} allowFullscreen="true" webkitAllowfullscreen="true" mozAllowfullscreen="true"></iframe></div> </>;

    const consultation = <> <div className="iframeContainer"><iframe title="consultation" src="https://momence.com/YogaWithJill/appointment-reservation/11176?boardId=1801&venueId=2454" style={{ width: "100%", minHeight: '1000px' }} allowFullscreen="true" webkitAllowfullscreen="true" mozAllowfullscreen="true"></iframe></div> </>;

    const navigate = useNavigate();
    const handleClick = (path) => {
        navigate(path);
    };

    const emailUs = () => {
        window.location.href =
            "mailto:jillian.gress033@gmail.com?subject=Message from website";
    };
    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            variant="light"
            bg="light"
            className="gradient pt-3 pb-4"
            // style={{ maxHeight: "50px" }}
            fixed="top"
        >
            <Container style={{ maxWidth: "90vw !important" }}>
                <Navbar.Brand
                    onClick={() => document.querySelector(".home").click()}
                    role="button"
                >
                    {/* <img
            alt="Phoenix logo"
            src={logo}
            width="50"
            height="50"
            className="d-inline-block"
          /> */}
                    <span className="fs-4 navBrand">{"Training With Jill"}</span>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto align-items-center">
                        <LinkContainer to="/">
                            <Nav.Link className="fs-5 home navLink">Home</Nav.Link>
                        </LinkContainer>
                        {/* <LinkContainer to="/about">
              <Nav.Link className="fs-5 about navLink">About</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/services">
              <Nav.Link className="fs-5 gallery navLink">Services</Nav.Link>
            </LinkContainer> */}


                        <ModalTemplate title="Consultation" body={consultation} />
                        <ModalTemplate title="Training" body={training} />
                        <ModalTemplate title="Videos" body={videoLibrary} />
                        {/* <Nav.Link className="fs-5 contact navLink" onClick={emailUs}>Contact</Nav.Link> */}
                        {/* <LinkContainer to="contact">
                            <Nav.Link className="fs-5 navLink">Contact</Nav.Link>
                        </LinkContainer> */}
                        {/* <ShareBtn /> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarBar;
