import React from "react";
import splashPhoto from "../images/og-splash.jpg";
import splashPhoto2 from "../images/jillSplash.jpeg";

import { Container, Row, Col, Button } from "react-bootstrap";
import useScript from '../helpers/getClasses.js'


const LandingPage = () => {


    return (
        <section>
            <div className="center splashWrapper">
                <img
                    className="img-fluid splashImage"
                    src={splashPhoto2}
                    alt="Jill doing yoga"
                />
            </div>
            {/* <div className="homeContent">
              <div className="mt-4 tagLineContainer">
              <div className="mt-4">
              <h2 className="tagLine text-center">{"Ignite Memories"}</h2>
              </div>
              <h4 className="text-center">
              {
                  "Bringing contemporary fire dancing to our communities through performance, collaboration, and education"
                }
                </h4>
                <div className="mt-4 center">
                <Button variant="primary" size="lg">
                Book Now!
                </Button>{" "}
                </div>
                </div>
            </div> */}
            {/* <Container className="contentStrip">
                <Row>
                    <Col className="vcenter">
                        <h5 className="homeBlurb">
                            {
                                "Fitness Blurb Here Lorem ipsum dolor, sit amet consectetur adipisicing elit. Totam porro suscipit ipsum! Quis totam ipsa sed suscipit eum vero ipsam voluptates adipisci esse delectus ex, commodi autem voluptatibus eaque nobis?"
                            }
                        </h5>
                    </Col>
                </Row>
            </Container> */}
            <div className="center mt-5">
                <h2>Upcoming Classes</h2>
            </div>
            <div className="center mb-5">
                <div id="ribbon-content"></div>{useScript('https://momence.com/v2.0/balanced-body-1643063143479.js')}
            </div>
        </section>
    );
};

export default LandingPage;
