import React from "react";
import ContactPage from './Contact';
import ConsultationPage from './Consultation';
import LandingPageContent from "./LandingPage";
// import Homepage from "./Home";
// import AboutPage from "./About";
// import ServicesPage from "./Services";


export const LandingPage = () => {
    return <LandingPageContent />;
};
// export const Home = () => {
//   return <Homepage />;
// };

// export const About = () => {
//   return <AboutPage />;
// };

export const Contact = () => {
  return <ContactPage />;
};

export const Consultation = () => {
  return <Consultation />;
};
// export const GetInvolved = () => {
//   return <h1>Get Involved</h1>;
// };
