import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
    Consultation,
    Contact,
    LandingPage,
} from "../pages";
// import { NavbarBar } from "./Navbar";
// import ScrollToTop from "../helpers/ScrollToTop";
import NavbarBar from "./Navbar";

const RoutePoints = () => {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <main className="App">
                <NavbarBar />
                <Routes>
                    <Route path="/" element={<LandingPage />}></Route>
                    {/* <Route path="contact" element={<Contact />}></Route> */}
                    <Route path='consultation' element={<Consultation />}></Route>
                    {/* <Route path="about" element={<About />}></Route>
          <Route path="services" element={<Services />}></Route>
          <Route path="get-involved" element={<GetInvolved />}></Route>
          <Route path="contact" element={<Contact />}></Route>
          <Route
            path="fire-performances"
            element={<FirePerformances />}
          ></Route>
          <Route path="LED-performances" element={<LEDPerformances />}></Route>
          <Route
            path="dance-performances"
            element={<DancePerformances />}
          ></Route> */}
                    <Route path="*" element={<LandingPage />} />
                </Routes>
            </main>
        </Router>
    );
};

export default RoutePoints;
