import { useState } from 'react';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';



const ModalTemplate = ({ title, body }) => {
    const [show, setShow] = useState(false);

    // const breakpoint = 'lg-down';
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    return (
        <>
            {/* <div className="center mt-3">
                <Button variant="primary" size="lg" onClick={handleShow}>
                    {button}
                </Button>
            </div> */}

            <Nav.Link className="fs-5 navLink" onClick={handleShow}>{title}</Nav.Link>

            <Modal show={show} fullscreen={true} size='lg' onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
            </Modal>
        </>
    );
};

export default ModalTemplate;
